import {
  LoggerContext,
  RepositoryContext,
  projectRepository,
} from "@src/shared/contexts";
import { useContext, useEffect, useMemo, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { SuccessToast } from "../toasts/SuccessToast";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../toasts/ErrorToast";
import { RootState } from "@src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { setProjectData } from "@src/redux/feature/projectSlice";
import { setLoading } from "@src/redux/feature/appSlice";
import { CustomLogger } from "@src/repositories/CustomLoggerRepository";

const AddMemberModal = ({ closeAddMemberModal }: AddMemberModalProps) => {
  const params = useParams();
  const orgId = params.orgId;

  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const projectSlice = useSelector((state: RootState) => state.projectSlice);

  useEffect(() => {
    const fetchData = async () => {
      const response = await projectRepository.getProject<ProjectData>(
        parseInt(orgId!)
      );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          dispatch(setProjectData(response));
        }
      }
    };

    fetchData()
      .catch(async (error) => {
        showBoundary(error);
        await customLogger.reportError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [CustomLogger, useDispatch, projectRepository, orgId]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<AddMemberFieldData>({
    defaultValues: {
      name: "",
      email: "",
      role: "Basic",
      projects: [],
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const selectedOption = watch("role");
  const selectedProjects = watch("projects");

  useEffect(() => {
    const fields: (keyof AddMemberFieldData)[] = [
      "name",
      "email",
      "role",
      "projects",
    ];

    fields.forEach((field) => {
      const subscription = watch((_, { name, type }) => {
        if (name === field && type === "change" && errors[field]) {
          clearErrors(field);
        }
      });

      return () => subscription.unsubscribe();
    });
  }, [watch, errors, clearErrors]);

  // Filter out the already selected projects and apply search filter
  const availableProjects = useMemo(() => {
    return projectSlice.projectData
      ?.filter((project) => !selectedProjects.includes(project.id))
      .filter((project) =>
        project.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
  }, [projectSlice.projectData, selectedProjects, searchQuery]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleProjectDropdown = () => {
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  };

  const handleOptionSelect = (option: string) => {
    setValue("role", option);
    setIsOpen(false);
  };

  const handleProjectDropdownSelect = (option: number) => {
    setValue("projects", [...selectedProjects, option]);
  };

  // Define the removeProject function
  const removeProject = (projectId: number) => {
    const updatedProjects = selectedProjects.filter(
      (project) => project !== projectId
    );
    setValue("projects", updatedProjects);
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const dispatch = useDispatch();

  const repository = useContext(RepositoryContext);
  const memberRepo = repository.member;

  const capitalizeFirstLetter = (title: string): string => {
    if (!title) return "";
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  const onSubmit = async (data: AddMemberFieldData) => {
    dispatch(setIsLoading(true));
    try {
      const response = await memberRepo.createMember<
        MemberData,
        AddMemberFieldData
      >(parseInt(orgId!), data);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "name" | "email" | "role";
            if (validField === "name" || "email" || "role") {
              setError(validField, { message: error.message });
            }
            if (
              validField !== "name" &&
              validField !== "email" &&
              validField !== "role"
            ) {
              ErrorToast({ Message: error.message });
              clearErrors();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeAddMemberModal?.();
          SuccessToast({
            Message: "Success! You have added a new member",
          });
        }
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-[28px] font-[700] text-text_black">
            Add Members
          </h2>
          <div className="mt-2">
            <label className="text-text_gray text-sm font-[500]" htmlFor="name">
              Name
              <span className="text-red text-sm ml-1">*</span>
            </label>
            <input
              {...register("name", {
                required: "Name must not be empty",
              })}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
            />
            {errors.name != null && (
              <small className="block text-red mt-2">
                {errors.name.message}
              </small>
            )}
          </div>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500]"
              htmlFor="email"
            >
              Email
              <span className="text-red text-sm ml-1">*</span>
            </label>
            <input
              {...register("email", {
                required: "Email must not be empty",
              })}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="email"
            />
            {errors.email != null && (
              <small className="block text-red mt-2">
                {errors.email.message}
              </small>
            )}
          </div>
          <div className="mt-2">
            <label className="text-text_gray text-sm font-[500]">Role</label>
            <span className="text-red text-sm ml-1">*</span>
            <div className="relative" onMouseLeave={() => setIsOpen(false)}>
              <button
                type="button"
                className="relative cursor-default border border-border_light_gray rounded-[10px] w-full h-[48px] py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
                onClick={toggleDropdown}
              >
                <span className="flex items-center">
                  <span className="block truncate">{selectedOption}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {isOpen ? (
                    <img
                      className="w-18 rotate-180"
                      src={`/icons/CustomSelectionArrow.svg`}
                      alt="Roles Dropdown Arrow"
                    />
                  ) : (
                    <img
                      className="w-18"
                      src={`/icons/CustomSelectionArrow.svg`}
                      alt="Roles Dropdown Arrow"
                    />
                  )}
                </span>
              </button>
              {isOpen && (
                <ul className="absolute z-10 w-full rounded-md bg-white shadow-lg focus:outline-none">
                  {["Basic", "Editor"].map((role) => (
                    <li
                      key={role}
                      className={`cursor-pointer select-none py-2 pr-9 ${
                        selectedOption === role ? "bg-[#F3F4F8]" : ""
                      }`}
                      onClick={() => handleOptionSelect(role)}
                    >
                      <div className="flex items-center">
                        <span className="font-normal ml-3 block truncate">
                          {role}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="mt-2">
            <label className="text-text_gray text-sm font-[500]">Project</label>
            <span className="text-red text-sm ml-1">*</span>
            <div
              className="relative"
              onMouseLeave={() => setIsProjectDropdownOpen(false)}
            >
              <button
                type="button"
                className="relative scrollbar-thin cursor-default border border-border_light_gray rounded-[10px] w-full max-h-20 h-fit  py-2 px-3 text-gray-700 focus:outline-none mt-[10px] overflow-y-auto"
                onClick={toggleProjectDropdown}
              >
                <span className="flex justify-start items-center">
                  <div className=" w-[100%] flex justify-start">
                    {selectedProjects && selectedProjects.length > 0 ? (
                      <div className="flex max-w-[90%] flex-wrap gap-2 ">
                        {selectedProjects.map((projectId) => {
                          const project = projectSlice.projectData?.find(
                            (proj) => proj.id === projectId
                          );
                          return (
                            project && (
                              <div
                                key={projectId}
                                className="flex items-center border border-[#4C4C4C] rounded-full px-[12px] text-[#4C4C4C] gap-4 whitespace-nowrap truncate"
                              >
                                {capitalizeFirstLetter(project.title)}
                                <button
                                  type="button"
                                  className="hover:text-[red] mt-1"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeProject(projectId);
                                  }}
                                >
                                  &#10005;
                                </button>
                              </div>
                            )
                          );
                        })}
                        <input
                          type="text"
                          className="border-0 focus:outline-none w-fit"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          value={searchQuery}
                        />
                      </div>
                    ) : (
                      "Select Project"
                    )}
                  </div>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {availableProjects.length > 0 &&
                    (isProjectDropdownOpen ? (
                      <img
                        className="w-18 rotate-180"
                        src="/icons/CustomSelectionArrow.svg"
                        alt="Project Dropdown Arrow"
                      />
                    ) : (
                      <img
                        className="w-18"
                        src="/icons/CustomSelectionArrow.svg"
                        alt="Project Dropdown Arrow"
                      />
                    ))}
                </span>
              </button>
              {isProjectDropdownOpen && (
                <div className="absolute z-10 w-full rounded-md bg-white shadow-lg focus:outline-none">
                  <ul className="scrollbar-thin w-full h-fit max-h-32 overflow-y-auto focus:outline-none rounded-md">
                    {availableProjects && availableProjects.length > 0 ? (
                      availableProjects.map((item) => (
                        <li
                          key={item.id}
                          className="cursor-pointer select-none py-2 pr-9 bg-[#F3F4F8] w-full hover:bg-[grey]"
                          onClick={() => handleProjectDropdownSelect(item.id)}
                        >
                          <div className="flex items-center">
                            <span className="font-normal ml-3 block truncate">
                              {capitalizeFirstLetter(item.title)}
                            </span>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="cursor-default select-none py-2 pr-9 bg-[#F3F4F8]">
                        <div className="flex items-center">
                          <span className="font-normal ml-3 block truncate">
                            No matching projects found
                          </span>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
            {errors.projects && (
              <small className="block text-red mt-2">
                {errors.projects.message}
              </small>
            )}
          </div>
          <div className="flex justify-start gap-[20px] mt-[16px]">
            {isLoading ? (
              <button
                disabled
                className="flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              >
                <img
                  width={22}
                  height={22}
                  src="/icons/Loading.svg"
                  alt="loading"
                />
                Adding...
              </button>
            ) : (
              <button
                type="submit"
                className={`${
                  !isValid || selectedProjects.length === 0
                    ? "bg-button_blue bg-opacity-[0.60]"
                    : "bg-button_blue"
                } w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md`}
                disabled={!isValid || selectedProjects.length === 0}
              >
                Send Invite
              </button>
            )}
            <button
              type="button"
              onClick={closeAddMemberModal}
              className="border border-button_blue w-[90px] h-[40px] text-nav_blue text-[14px] font-[500] rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMemberModal;
