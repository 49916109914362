import { useContext, useEffect, useState } from "react";
import Premium from "./Premium";
import { useErrorBoundary } from "react-error-boundary";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import SubscriptionCard from "@src/components/sharedComponents/card/SubscriptionCard";
import { useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import SubscriptionSuccessModal from "@src/components/sharedComponents/modal/SubscriptionSuccessModal";

const Subscription = () => {
  const [isActive, setIsActive] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState<Subscription | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);

  const isSubscriptionSuccessModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isSubscriptionSuccessModalOpen
  );

  const premium = true;

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const subscriptionRepo = repository.subscription;

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await subscriptionRepo.getSubscription<Subscription>();
        if (!_.isUndefined(response)) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
          } else {
            setSubscriptionData(response);
            setInitialActiveState(response);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
      setIsLoading(false);
    };

    const setInitialActiveState = (data: Subscription) => {
      const currentPlanId =
        data.current_subscription_plan?.subscription_plan_id_current;
      const matchedPlan = _.find(
        data.subscription_plan_list,
        (plan) => plan.id === currentPlanId
      );
      if (matchedPlan) {
        const currentPriceId =
          data.current_subscription_plan?.stripe_subscription_price_id;
        const matchingPriceDetail = _.find(
          matchedPlan.price_detail,
          (priceDetail) => priceDetail.id === currentPriceId
        );

        if (matchingPriceDetail) {
          setIsActive(matchingPriceDetail.recurring.interval === "month");
        }
      }
    };
    fetchSubscriptionData();
  }, [customLogger, showBoundary, subscriptionRepo]);

  const toggleIsActive = () => setIsActive(!isActive);

  const getSubscriptionCards = (billingPeriod: string) => {
    return [
      {
        title: "Starter" as SubscriptionTitle,
        price: 0,
        period: billingPeriod === "monthly" ? "Month" : "Year",
        billingPeriod,
      },
      {
        title: "Startup" as SubscriptionTitle,
        period: billingPeriod === "monthly" ? "Month" : "Year",
        billingPeriod,
      },
      {
        title: "Enterprise" as SubscriptionTitle,
        period: billingPeriod === "monthly" ? "Month" : "Year",
        billingPeriod,
      },
    ];
  };

  const mapFeatures = (planList: SubscriptionPlan[]) => {
    return _.reduce(
      planList,
      (acc, plan) => {
        _.forEach(plan.feature, (feature) => {
          acc[plan.name] = feature;
        });
        return acc;
      },
      {} as { [key in SubscriptionTitle]: Feature }
    );
  };

  const mapPriceDetails = (
    planList: SubscriptionPlan[],
    billingPeriod: string
  ) => {
    return _.reduce(
      planList,
      (acc, plan) => {
        const matchingPriceDetail = _.find(
          plan.price_detail,
          (priceDetail) =>
            priceDetail.recurring.interval ===
            (billingPeriod === "monthly" ? "month" : "year")
        );
        if (matchingPriceDetail) {
          acc[plan.name] = matchingPriceDetail;
        }
        return acc;
      },
      {} as { [key in SubscriptionTitle]: PriceDetail }
    );
  };

  const getCurrentPlanName = () => {
    const currentPlanId =
      subscriptionData?.current_subscription_plan?.subscription_plan_id_current;
    const matchedPlan = _.find(
      subscriptionData?.subscription_plan_list,
      (plan) => plan.id === currentPlanId
    );
    return matchedPlan?.name;
  };

  const renderSubscriptionCards = (billingPeriod: string) => {
    const cards = getSubscriptionCards(billingPeriod);
    const mappedFeatures = mapFeatures(
      subscriptionData?.subscription_plan_list || []
    );
    const mappedPriceDetails = mapPriceDetails(
      subscriptionData?.subscription_plan_list || [],
      billingPeriod
    );

    const priorityOrder: SubscriptionTitle[] = [
      "Starter",
      "Startup",
      "Enterprise",
    ];
    const sortedCards = _.sortBy(cards, (card) =>
      priorityOrder.indexOf(card.title as SubscriptionTitle)
    );

    return _.map(sortedCards, (card, index) => (
      <SubscriptionCard
        key={index}
        currentPlan={getCurrentPlanName()}
        title={card.title}
        price={mappedPriceDetails[card.title]?.unit_amount||card.price}
        period={card.period}
        billingPeriod={card.billingPeriod}
        feature={mappedFeatures[card.title]}
        price_detail={mappedPriceDetails[card.title]}
        current_price_id={
          subscriptionData?.current_subscription_plan
            ?.stripe_subscription_price_id
        }
      />
    ));
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <img
            width={100}
            height={100}
            src="/icons/Loading.svg"
            alt="loading"
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center text-[#172B4D] py-[34px]">
          {premium ? (
            <div>
              <div className="text-[#172B4D] flex flex-col gap-5 items-center">
                <h1 className="text-[32px] font-bold">Get Unlimited Access</h1>
                <p className="text-[14px]">
                  Select a plan that fits your business needs starting from
                  $18/month
                </p>
                <div className="bg-[#325EBB] w-[170px] h-[44px] text-[12px] font-medium flex rounded-full items-center justify-center">
                  <button
                    onClick={toggleIsActive}
                    className={`${
                      isActive
                        ? "bg-[#FFFFFF] text-[#325EBB]"
                        : "bg-[#325EBB] text-[#FFFFFF]"
                    } w-[80px] h-[34px] rounded-full`}
                  >
                    Monthly
                  </button>
                  <button
                    onClick={toggleIsActive}
                    className={`${
                      !isActive
                        ? "bg-[#FFFFFF] text-[#325EBB]"
                        : "bg-[#325EBB] text-[#FFFFFF]"
                    } w-[80px] h-[34px] rounded-full`}
                  >
                    Yearly
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-center mt-[25px]">
                <div className="flex gap-[50px]">
                  {renderSubscriptionCards(isActive ? "monthly" : "annually")}
                </div>
              </div>
            </div>
          ) : (
            <Premium />
          )}
          {isSubscriptionSuccessModalOpen && <SubscriptionSuccessModal />}
        </div>
      )}
    </div>
  );
};

export default Subscription;
