import { parseApiResponse } from "@actions/parseApiResponse";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
// import { IMemberRepository } from "./types";

export class SubscriptionRepository {
  private _api: IJsonAPI;

  private Subscription_URL = RepositoryURL.SUBSCRIPTION_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async getSubscription<SubscriptionData>() {
    const url = `${this.Subscription_URL}plans/`;
    const response = await this._api.get<SubscriptionData>(url);
    return parseApiResponse(response);
  }

  async createSession<sessionData, CreateSessionData>(
    orgId: string | undefined,
    data: CreateSessionData
  ): Promise<sessionData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const session_url = `${this.Subscription_URL}organization/${orgId}/checkout/create-session/`;
    const response = await this._api.post<sessionData, CreateSessionData>(
      session_url,
      data,
      headers
    );
    return parseApiResponse<sessionData>(response);
  }

  async verifySession<sessionData, sessionID>(
    orgId: string | undefined,
    data: sessionID
  ): Promise<sessionData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const session_url = `${this.Subscription_URL}organization/${orgId}/stripe/verify-session/`;
    const response = await this._api.post<sessionData, sessionID>(
      session_url,
      data,
      headers
    );
    return parseApiResponse<sessionData>(response);
  }

  async getBillingAddress<billingData>(orgId: number) {
    const url = `${this.Subscription_URL}organization/${orgId}/invoice-billing/`;
    const response = await this._api.get<billingData>(url);
    return parseApiResponse(response);
  }

  async createInvoice<billingResponse, billingData>(
    orgId: number,
    data: billingData
  ): Promise<billingResponse | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const billing_url = `${this.Subscription_URL}organization/${orgId}/invoice-billing/`;
    const response = await this._api.post<billingResponse, billingData>(
      billing_url,
      data,
      headers
    );
    return parseApiResponse<billingResponse>(response);
  }
}
