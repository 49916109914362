import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Usage = () => {
  const repository = useContext(RepositoryContext);
  const profileRepo = repository.profile;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const params = useParams();
  const orgId = params.orgId!;
  const [usageData, setUsageDate] = useState<UsageData>();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  useEffect(() => {
    dispatch(setIsLoading(true));
    const fetchData = async () => {
      try {
        const response = await profileRepo.getUsage<UsageData>(orgId);
        if (response) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
          } else {
            setUsageDate(response);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchData();
  }, [customLogger, profileRepo, showBoundary]);

  const availableAtSignIn =
    (usageData?.available_ai_token ?? 0) + (usageData?.used_ai_token ?? 0);
  const usedTokens = usageData?.used_ai_token ?? 0;
  const availableTokens = usageData?.available_ai_token ?? 0;

  const calculateWidth = (tokens: number) => {
    const width = (tokens / availableAtSignIn) * 100;
    return width < 0 ? "0%" : width + "%";
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-[90vh]">
          <img src="/icons/Loading.svg" alt="loading" />
        </div>
      ) : (
        <div className="p-[25px] md:p-[36px]">
          <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
            Tokens
          </h1>
          <div className="mt-[40px] h-[22px] w-full rounded-full bg-[#647DDB] overflow-hidden">
            <div
              className="h-full bg-[#A65428]"
              style={{ width: calculateWidth(usedTokens) }}
            ></div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-[18px] mt-[26px] ml-3">
              <div className="flex items-center gap-3 text-[16px]">
                <div className="h-[5px] w-[35px] rounded-full bg-[#A65428]"></div>
                <p>Tokens Used ({usedTokens.toLocaleString()})</p>
              </div>
              <div className="flex items-center gap-3 text-[16px]">
                <div className="h-[5px] w-[35px] rounded-full bg-[#647DDB]"></div>
                <p>Tokens Available ({availableTokens.toLocaleString()})</p>
              </div>
            </div>
            <div className="flex flex-col gap-[18px] mt-[26px] ml-3">
              <div className="flex items-center gap-3 text-[16px]">
                <p>Available Errors ({usageData?.available_error})</p>
              </div>
              <div className="flex items-center gap-3 text-[16px]">
                <p>Available Emails ({usageData?.available_email_alert})</p>
              </div>
            </div>
          </div>
          <div className="mt-[18px] ml-3">
            ({availableAtSignIn.toLocaleString()} tokens are available while
            signing up)
          </div>
        </div>
      )}
    </>
  );
};

export default Usage;
