import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "@redux/store";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import "./index.css";
import { App } from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomErrorBoundary } from "@sharedComponents/errorHandling/CustomErrorBoundary";
import { LoggerProvider } from "@providers/LoggerProvider";
import { customLogger } from "@shared/contexts";
import { CustomLogger } from "retack-sdk-app-observer";

const clientId = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID;
const environmentKey = import.meta.env.VITE_ENV_KEY;
const googleAdsId = import.meta.env.VITE_GOOGLE_ADS_ID;

CustomLogger.init(environmentKey);

const Root = () => {
  useEffect(() => {
    // Dynamically load Google Ads script
    const adsScript = document.createElement("script");
    adsScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`;
    adsScript.async = true;
    document.head.appendChild(adsScript);

    // Initialize Google Ads
    adsScript.onload = () => {
      (window as any).dataLayer = (window as any).dataLayer || [];
      const gtag = (...args: any[]) => {
        (window as any).dataLayer.push(args);
      };
      gtag("js", new Date());
      gtag("config", googleAdsId);
    };

    // Cleanup
    return () => {
      document.head.removeChild(adsScript);
    };
  }, [googleAdsId]);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <LoggerProvider customLogger={customLogger}>
          <CustomErrorBoundary>
            <GoogleOAuthProvider clientId={clientId}>
              <App />
            </GoogleOAuthProvider>
          </CustomErrorBoundary>
        </LoggerProvider>
      </Provider>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);
